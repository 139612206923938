import React, { useEffect, useState } from "react";
import useSession from "../hooks/useSession";
import CircleAccCorsi from "../assets/CircleAccCorsi.png";
import CircleAccAreaPers from "../assets/CircleAccAreaPers.png";
import CircleAccWebinar from "../assets/CircleAccWebinar.png";
import CircleAccCashback from "../assets/CircleAccCashback.png";
import BannerHomeSTB from "../assets/BannerHomeSTB.png";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AxiosClient from "../client/client";
import Carousel from "react-bootstrap/Carousel";
import { jwtDecode } from "jwt-decode";

const Home = () => {
  const session = useSession();
  const client = new AxiosClient();
  const navigate = useNavigate();
  const [lastCourses, setLastCourses] = useState([]);
  const [lastWebinars, setLastWebinars] = useState([]);

  const getLastWebinars = async () => {
    try {
      const response = await client.get("/webinars/latest");
      setLastWebinars(response);
    } catch (e) {
      console.log(e);
    }
  };

  const getLastCourses = async () => {
    try {
      const response = await client.get("/courses/latest");
      setLastCourses(response);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getLastWebinars();
    getLastCourses();
  }, []);

  //prova per vedere se funziona il controllo del token

  const logout = () => {
    // Rimuovi il token di autenticazione
    localStorage.removeItem("loggedInUser");
    navigate("/");
  };

  useEffect(() => {
    const fetchProtectedData = async () => {
      const token = localStorage.getItem("loggedInUser"); // Recupera il token dallo storage locale

      if (!token) {
        logout();
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/apiProtected`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          console.log("TUTTO OK");
        } else if (response.status === 403) {
          logout();
          console.log("403");
        } else {
          logout();
          console.log("ELSE");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchProtectedData();
  }, []);

  return (
    <>
      <Container className="margin_T150 text-center">
        <h2 className="mb-4">Bentornato nella tua area riservata!</h2>
        <p>
          Avrai accesso esclusivo al tuo resoconto acquisti, a sconti
          personalizzati e al cashback guadagnato. <br /> Abbiamo preparato per
          te una serie di webinar gratuiti (che verranno caricati a breve) su
          Autodesk Inventor e Vault che potrai seguire in qualsiasi momento e
          dal 2025 lanceremo una serie di mini-corsi che ti aiuteranno a
          migliorare le tue competenze. <br /> Resta connesso per non perdere le
          nostre novità!
        </p>
      </Container>

      <Container fluid>
        <Row className="d-flex flex-wrap justify-content-center my-5">
          <Col
            className="boxImmagini d-flex flex-wrap justify-content-center"
            sm={12}
            xl={12}
          >
            {/* <Link to={"/corsi"}> */}
            <Link to={"/workInProgressCorsi"}>
              <img
                className="m-3 mx-5 circleImgLink"
                src={CircleAccCorsi}
                alt=""
              />
            </Link>
            {/* <Link to={"/webinar"}> */}
            <Link to={"/workInProgressWebinar"}>
              <img
                className="m-3 mx-5 circleImgLink"
                src={CircleAccWebinar}
                alt=""
              />
            </Link>
            {/* <Link to={`/dettagliAccount/${session.id}`}>
              <img
                className="m-3 circleImgLink"
                src={CircleAccAreaPers}
                alt=""
              />
            </Link> */}
            {session.role === "azienda" && (
              <Link to={`/aziendaInfo/${session.id}`}>
                <img
                  className="m-3 mx-5 circleImgLink"
                  src={CircleAccCashback}
                  alt=""
                />
              </Link>
            )}
          </Col>
        </Row>
      </Container>

      {/* BANNER STBOOSTERS */}
      <div className="d-flex justify-content-center mt-3 mb-5">
        <Link
          target="_blank"
          to="https://www.symbiotech.it/st-boosters/"
          className="text-center w-50"
        >
          <img className="w-100" src={BannerHomeSTB} alt="" />
        </Link>
      </div>

      {/* CAROSELLI ULTIMI WEBINAR E CORSI */}
      {/* <Container fluid>
        <Row className="d-flex flex-wrap justify-content-center mb-5">
          <Col
            className=" d-flex flex-wrap justify-content-around"
            sm={12}
            xl={12}
          >
            <div>
              <h5 className="text-center text_Color_Grigio">
                NUOVI WEBINAR DISPONIBILI
              </h5>
              <Carousel
                pause={"hover"}
                style={{ width: "400px" }}
                variant={"dark"}
                indicators={false}
                className="mt-3"
              >
                {lastWebinars &&
                  lastWebinars.webinars?.map((webinar) => {
                    return (
                      <Carousel.Item interval={5000}>
                        <img
                          src={webinar.cover}
                          alt=""
                          style={{
                            height: "200px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                        <Carousel.Caption></Carousel.Caption>{" "}
                        <Link
                          to={`/webinar/${webinar._id}`}
                          className="clear_link_black text-center"
                        >
                          <h3 className="mt-2">{webinar.titolo}</h3>
                        </Link>
                      </Carousel.Item>
                    );
                  })}
              </Carousel>
            </div>
            <div>
              <h5 className="text-center">NUOVI CORSI DISPONIBILI</h5>
              <Carousel
                pause={"hover"}
                style={{ width: "400px" }}
                variant={"dark"}
                indicators={false}
                className="mt-3"
              >
                {lastCourses &&
                  lastCourses.courses?.map((course) => {
                    return (
                      <Carousel.Item interval={5000}>
                        <img
                          src={course.cover}
                          alt=""
                          style={{
                            height: "200px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                        <Carousel.Caption></Carousel.Caption>
                        <Link
                          to={`/webinar/${course._id}`}
                          className="clear_link_black text-center"
                        >
                          <h3 className="mt-2">{course.titolo}</h3>
                        </Link>
                      </Carousel.Item>
                    );
                  })}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container> */}
    </>
  );
};

export default Home;
